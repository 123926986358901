import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../program/program.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-show-part',
  templateUrl: './show-part.component.html',
  styleUrls: ['./show-part.component.css']
})
export class ShowPartComponent {


  constructor(
    public dialogRef: MatDialogRef<ShowPartComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}


