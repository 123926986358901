import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../services/api.service';
import {SubCat, TimetableInput, TimetableOutput, TimetableOutputRaw} from '../services/dataClass';
import {DatePipe} from '@angular/common';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-show-timetable',
  templateUrl: './show-timetable.component.html',
  styleUrls: ['./show-timetable.component.css']
})
export class ShowTimetableComponent implements OnInit{

  dataSource: TimetableOutput[] = [];
  subCat: SubCat[];

  constructor(public api: ApiService, private datePipe: DatePipe,
              public dialogRef: MatDialogRef<ShowTimetableComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TimetableInput) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.api.readTimetable(
      new TimetableInput(new Date(this.datePipe.transform(this.data.day, 'yyyy-MM-dd')), this.data.cat)).subscribe(
        (dataSource: TimetableOutputRaw[]) => {
          for (const i of dataSource){
            this.dataSource.push(new TimetableOutput(i));
          }
    });
    this.api.readSubCat().subscribe((data: SubCat[]) => {
      this.subCat = data;
    });
  }

}
