<h1 mat-dialog-title class="text-uppercase text-center">Faire un virement banquaire pour soutenir la Foire</h1>
<hr>
<div mat-dialog-content class="text-uppercase text-center">
  <h2><strong>Merci de vouloir soutenir la foire !</strong></h2>
  <p>Pour nous effectuer un don, voici les adresses des portefeuilles disponibles :</p>
  <p class="pt-2 font-weight-bold">BTC :</p>
  <p>XXXXXXXXXXXXXXX</p>
  <p class="pt-2 font-weight-bold">ETH :</p>
  <p>0xa86211552747fdD9B5405AB483B0aeaD15C4B055</p>
  <p class="pt-2 font-weight-bold">BNB :</p>
  <p>0xa86211552747fdD9B5405AB483B0aeaD15C4B055</p>
  <p class="pt-2 font-weight-bold">ADA :</p>
  <p>XXXXXXXXXXXXXXX</p>
  <p class="pt-3 font-italic">Merci de préciser en commentaire <strong>"DON pour la foire"</strong></p>
</div>
<div mat-dialog-actions class="row d-flex justify-content-end">
  <button mat-dialog-close class="btn btn-outline-dark">Fermer</button>
</div>
