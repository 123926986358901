import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participer',
  templateUrl: './participer.component.html',
  styleUrls: ['./participer.component.css']
})
export class ParticiperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
