<div class="container">
  <div class="text-upercase text-center pt-5 display-1">La Foire en forêt</div>
  <h2 class="text-center">Cantine de Sauvabelin : 21 et 22 août 2021</h2>
  <div class="col">
    <div class="p-2 text-center">
      <p>Suite au succès de La Foire du Désert, on a décidé de réitérer l’aventure cet été avec <strong>La Foire en Forêt !</strong></p>
      <p>Cette année, l’événement se déroulera du 21 au 22 août à la « magnifique » cantine de sauvabelin !</p>
      <p>Comme l’an dernier, l’idée est de mélanger les arts et cultures (ou juste cultures ou juste arts).</p>
      <p>Il y aura donc des ventes/stands de créations artisanales, des performances artistiques, ainsi que des concerts live et DJ sets tout le weekend.</p>
      <h3><strong>N’hésite pas et viens découvrir des artisans de la région !</strong></h3>
      <a href="https://www.facebook.com/events/191166769649411/" target="_blank"><h2 class="p-4"> > EVENT FB < </h2></a>
    </div>
    <div class="d-flex justify-content-center" >
      <div style="max-width: 350px;">
        <img class="img-fluid" src="assets/affiche.png">
      </div>
    </div>
  <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-6 col-md-4">
        <a [routerLink]="['/programme', 1]"><h2 class="text-center p-4"> > Programme < </h2></a>
        <a routerLink="/soutenir"><h2 class="text-center p-4"> > Soutenir < </h2></a>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <a routerLink="/info"><h2 class="text-center p-4"> > Infos < </h2></a>
        <a routerLink="/participer"><h2 class="text-center p-4"> > Participer < </h2></a>
      </div>
  </div>
    <hr>
    <div class="p-2 text-center">
      <h3><strong>Cet événement est soutenu par le Fonds de soutien à des projets de jeunes de La Ville de Lausanne.</strong></h3>
    </div>
    <div class="d-flex justify-content-center" style="height: 100px;">
      <a href="https://www.lausanne.ch/officiel/administration/enfance-jeunesse-et-quartiers.html" target="_blank">
        <img class="mx-auto d-block h-100" src="assets/partenaire/lausanne2.png">
      </a>
    </div>
    <hr>
    <div class="p-2 text-center">
      <h3><strong>Organisé par La Sacrée Déter & Co.</strong></h3>
    </div>
    <div class="d-flex justify-content-center" style="height: 150px;">
      <a href="https://www.lasacreedeter.art" target="_blank">
        <img class="mx-auto d-block h-100" src="assets/partenaire/logo_lsd.png">
      </a>
    </div>
    <div class="p-3"></div>
  </div>
</div>
