import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {Participant, TimetableInput, TimetableOutputRaw, Category, SubCat, Event, ParticipantFull, MailInput} from './dataClass';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  PHP_API_SERVER = 'https://lafoire.ch';

  constructor(private httpClient: HttpClient) {}

  readEvent(): Observable<Event[]>{
    return this.httpClient.get<Event[]>(`${this.PHP_API_SERVER}/html/readEvent.php`);
  }

  readCategory(): Observable<Category[]>{
    return this.httpClient.get<Category[]>(`${this.PHP_API_SERVER}/html/readCategory.php`);
  }

  readSubCat(): Observable<SubCat[]>{
    return this.httpClient.get<SubCat[]>(`${this.PHP_API_SERVER}/html/readSubCat.php`);
  }

  readParticipants(): Observable<Participant[]>{
    return this.httpClient.get<Participant[]>(`${this.PHP_API_SERVER}/html/readParticipants.php`);
  }

  readParticipantsFull(): Observable<Participant[]>{
    return this.httpClient.get<ParticipantFull[]>(`${this.PHP_API_SERVER}/html/readParticipants.php`);
  }

  readTimetable(input: TimetableInput): Observable<TimetableOutputRaw[]>{
    return this.httpClient.post<TimetableOutputRaw[]>(`${this.PHP_API_SERVER}/html/readTimetable.php`, input);
  }

  sendMail(input: MailInput): Observable<string>{
    return this.httpClient.post<string>(`${this.PHP_API_SERVER}/html/mail.php`, input);
  }
}

