import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';

import { AppComponent } from './app.component';
import {ProgramComponent} from './program/program.component';
import {Routes, RouterModule} from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ContactComponent } from './contact/contact.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ShowPartComponent } from './show-part/show-part.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import { ShowTimetableComponent } from './show-timetable/show-timetable.component';
import {MatTableModule} from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ParticiperComponent } from './participer/participer.component';
// tslint:disable-next-line:max-line-length
import { SoutenirComponent, SoutenirComponentIbanDialog, SoutenirComponentTwintDialog, SoutenirComponentCashDialog, SoutenirComponentCryptoDialog } from './soutenir/soutenir.component';
import { AdminComponent } from './admin/admin.component';
import { AdminFormComponent } from './admin-form/admin-form.component';
import { InfoComponent } from './info/info.component';

registerLocaleData(localeFr, 'fr');


const route: Routes = [
  { path: 'contact', component: ContactComponent},
  { path: 'participer', component: ParticiperComponent},
  { path: 'soutenir', component: SoutenirComponent},
  { path: 'info', component: InfoComponent},
  { path: 'programme/:event', component: ProgramComponent},
  { path: 'programme/:event', component: ProgramComponent},
  { path: 'home', component: HomeComponent},
  { path: '', component: HomeComponent},
  { path: '**', component: ErrorComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    ProgramComponent,
    HomeComponent,
    ErrorComponent,
    ContactComponent,
    ShowPartComponent,
    ShowTimetableComponent,
    ParticiperComponent,
    SoutenirComponent,
    AdminComponent,
    AdminFormComponent,
    InfoComponent,
    SoutenirComponentIbanDialog,
    SoutenirComponentTwintDialog,
    SoutenirComponentCashDialog,
    SoutenirComponentCryptoDialog
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(route),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    AccordionModule,
    MatTableModule,
  ],
  providers: [
    DatePipe,
    {provide: LOCALE_ID, useValue: 'fr' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
