<nav class="navbar navbar-light navbar-expand-md">
  <div class="container d-flex align-items-start justify-content-between justify-content-md-start">
    <a class="navbar-brand text-uppercase" routerLink="/home"><h3 class="display-5">La Foire</h3></a>
    <div class="navbar-nav ml-auto">

      <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }"id="navbarNav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/info">Infos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/programme', 1]">Programme</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/participer">Participer</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/soutenir">Soutenir</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact">Contact</a>
        </li>
      </div>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
