<h1 mat-dialog-title class="text-uppercase text-center">Faire un virement banquaire pour soutenir la Foire</h1>
<hr>
<div mat-dialog-content class="text-uppercase text-center">
  <h2><strong>Merci de vouloir soutenir la foire !</strong></h2>
  <p>Pour nous effectuer un don en cash, vous avez plusieurs possibilités:</p>
  <p class="pt-2 font-weight-bold">Avant ou après l'événement :</p>
  <p>Vous pouvez sans autre nous contacter via <a href="/contact">notre formulaire de contact</a> et nous pourrons convenir d'un rendez-vous</p>
  <p class="pt-2 font-weight-bold">Pendant l'événement : </p>
  <p>Une boite à don sera disponible au bar et à l'entrée. Elle acceptera vos dons avec plaisir</p>
</div>
<div mat-dialog-actions class="row d-flex justify-content-end">
  <button mat-dialog-close class="btn btn-outline-dark">Fermer</button>
</div>
