import {Component, HostListener, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {Event, MailInput} from '../services/dataClass';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactSection = this.fb.group({
    name: ['', [Validators.pattern('[a-zA-Z ]*'), Validators.required, Validators.min(3), Validators.max(30)]],
    email: ['', [Validators.email, Validators.required]],
    message: ['', [Validators.pattern('[a-zA-Z.,-?!\'\\n ]*'), Validators.required, Validators.max(200)]]
  });
  color = true;
  resp = '';
  mailInput = 'leo@test.com';
  nameInput = 'leo';
  messageInput = 'message de test';
  constructor(private fb: FormBuilder, public api: ApiService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    // tslint:disable-next-line:max-line-length
    const inp = new MailInput(this.contactSection.controls.email.value, this.contactSection.controls.name.value, this.contactSection.controls.message.value);
    this.api.sendMail(inp).subscribe((value: string) => {
      this.resp = value;
    });
    alert('Merci, votre message a bien été envoyé.');
    this.contactSection.reset();
  }

}
