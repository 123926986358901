<div *ngIf="isLoaded >= 5">
  <div class="container">
    <div *ngIf="eventId === 0" class="row text-uppercase d-flex align-items-end justify-content-between">
      <div class="display-4">
        {{event[eventId].name}} :
      </div>
      <div class="display-4">
         Programme
      </div>
    </div>
    <div *ngIf="eventId === 1" class="row text-uppercase d-flex align-items-end justify-content-center">
      <div class="display-4">
        Programme
      </div>
    </div>
    <hr>
    <div *ngIf="true">
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="categoryDisplayed" (selectedTabChange)="selectParticipant($event.index)">
      <div *ngFor="let cat of category; let i = index">
        <mat-tab><ng-template mat-tab-label>
          <a class = "btn text-secondary m-2">{{cat.name}}</a>
        </ng-template>
          <ng-template matTabContent>
          <div *ngIf="categoryDisplayed === 1 || categoryDisplayed === 0">
            <div *ngFor="let day of eventDays">
                <div class="h4 text-uppercase text-center clearfix pt-5" accordion-heading>{{day | date:'EEEE d MMMM'}}</div>
                <div *ngIf="categoryDisplayed === 1 || categoryDisplayed === 2" class="d-flex justify-content-end"><button class="btn btn-dark" (click)="openTimeTable(categoryDisplayed, day)">TIMETABLE</button></div>
                <div class="row d-flex justify-content-center">
                  <div *ngFor="let element of participantsDisplayed;">
                    <div *ngIf="+element.dateShow.getDay() === +day.getDay()">
                      <div class="square-card m-3 text-white" style="background-position: center; background-size: cover;" [ngStyle]="{ 'background-image': 'url( /assets/' + element.photo + ')'}" (click)="openParticipant(element.id)" (mouseenter)="enter(element.id)" (mouseleave)="leave(element.id)">
                        <div class="card-content d-flex align-items-center justify-content-center" *ngIf="hoverIndex == element.id">
                          <div class="text-center h5">{{element.name}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div *ngIf="categoryDisplayed === 2">
            <h2 class="text-center text-uppercase p-5">Annoncé prochainement...</h2>
          </div>
          </ng-template>
        </mat-tab>
      </div>
    </mat-tab-group>
    </div>
  </div>
</div>
