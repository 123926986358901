<div class="container">
<div class="text-uppercase text-center">
  <div class="display-4">
    Contact
  </div>
  <hr>
  </div>
</div>
<div class="d-flex justify-content-center">
<form class="text-center border border-light p-5" [formGroup]="contactSection" (ngSubmit)="onSubmit()">

  <p class="h4 mb-4">Envoyez nous un message</p>

  <!-- Name -->
  <input type="text" formControlName="name" id="namr"
         class="form-control mb-4" placeholder="Nom">
  <div class="form-control-feedback"
       *ngIf="contactSection.controls.name.errors && (contactSection.controls.name.dirty || contactSection.controls.name.touched)">
    <p *ngIf="contactSection.controls.name.errors.required">Merci de mettre un nom</p>
    <p *ngIf="contactSection.controls.name.errors.min">Merci de mettre un nom un peu plus long</p>
    <p *ngIf="contactSection.controls.name.errors.max">Merci de mettre un nom un peu moins long</p>
    <p *ngIf="contactSection.controls.name.errors.pattern">Merci de mettre un nom pas trop tordu</p>
  </div>
  <!-- Email -->
  <input type="email" formControlName="email" id="email" name="email"
         class="form-control mb-4" placeholder="E-mail">
  <div class="form-control-feedback"
       *ngIf="contactSection.controls.email.errors && (contactSection.controls.email.dirty || contactSection.controls.email.touched)">
    <p *ngIf="contactSection.controls.email.errors.required">Merci de mettre un email</p>
    <p *ngIf="contactSection.controls.email.errors.email">Merci de mettre un email valide</p>
  </div>
  <!-- Message -->
  <div class="form-group">
    <textarea formControlName="message" class="form-control rounded-0" id="message"
              rows="3" placeholder="Message"></textarea>
  </div>
  <div class="form-control-feedback"
       *ngIf="contactSection.controls.message.errors && (contactSection.controls.name.dirty || contactSection.controls.message.touched)">
    <p *ngIf="contactSection.controls.message.errors.required">Merci de mettre un message</p>
    <p *ngIf="contactSection.controls.message.errors.pattern">Merci de mettre un message valide</p>
    <p *ngIf="contactSection.controls.message.errors.max">Merci de mettre un message un peu moins long...</p>
  </div>

  <!-- Send button -->
  <button  class="btn btn-outline-dark"
           type="submit" [disabled]="!contactSection.valid">Envoyer</button>
</form>
</div>
