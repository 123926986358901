<div style="height: 400px; width: 100%;  background-position: center; background-size: cover;" [ngStyle]="{ 'background-image': 'url( '+ data.photo + ')'}">

</div>
<h1>{{data.name}}</h1>
<h2>{{data.subCat}}</h2>
<h3 *ngIf="data.showTimeBool">{{data.startTime}} - {{data.stopTime}}</h3>
<mat-dialog-content>{{data.description}}</mat-dialog-content>
<div class="d-flex flex-row justify-content-around p-3">
  <a *ngIf="data.fbLink" href="{{data.fbLink}}" target="_blank"><button mat-mini-fab color="primary" aria-label="Facebook"><i class="fa fa-facebook"></i></button></a>
  <a *ngIf="data.fbLink2" href="{{data.fbLink2}}" target="_blank"><button mat-mini-fab color="primary" aria-label="Facebook"><i class="fa fa-facebook"></i></button></a>
  <a *ngIf="data.instLink" href="{{data.instLink}}" target="_blank"><button mat-mini-fab color="primary" aria-label="Instagram"><i class="fa fa-instagram"></i></button></a>
  <a *ngIf="data.instLink2" href="{{data.instLink2}}" target="_blank"><button mat-mini-fab color="primary" aria-label="Instagram"><i class="fa fa-instagram"></i></button></a>
  <a *ngIf="data.soundLink" href="{{data.soundLink}}" target="_blank"> <button mat-mini-fab color="primary" aria-label="Soundcloud"><i class="fa fa-soundcloud"></i></button></a>
  <a *ngIf="data.soundLink2" href="{{data.soundLink2}}" target="_blank"> <button mat-mini-fab color="primary" aria-label="Soundcloud"><i class="fa fa-soundcloud"></i></button></a>
  <a *ngIf="data.otherLink" href="{{data.otherLink}}" target="_blank"> <button mat-mini-fab color="primary" aria-label="Other"><i class="fa fa-globe"></i></button></a>
</div>

