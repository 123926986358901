<div class="container">
  <div class="text-uppercase text-center">
    <div class="display-4">
      Participer
    </div>
    <hr>
    <div class="h4">Artistes, Artisans, Performeurs</div>
    <p>Si tu désires participer à la prochaine édition de la Foire, n'hésite pas à entrer en contact avec nous : </p>
    <a routerLink="/contact"><h4 class="p-4"> > Contact < </h4></a>
    <hr>
    <div class="h4">Staff</div>
    <p>Le bon déroulement de cet évènement ne pourrait guère se faire sans le précieux travaille qu’apportent les bénévoles.

       </p>
    <p>si tu es chaud·x·e à nous offrir de ton temps et de ton aide pour cette nouvelle édition, inscrit toi ici : </p>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdChs7nn9rxOREx61u_CyWhOZAaeTd6n5qtnmv5cVTPbJ9rgg/viewform" target="_blank"><h4 class="p-4"> > INSCRIPTION < </h4></a>
  </div>
</div>
