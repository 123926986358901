import {Component, OnInit, OnDestroy, Inject, AfterViewInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ShowPartComponent} from '../show-part/show-part.component';
import {ShowTimetableComponent} from '../show-timetable/show-timetable.component';
import {Event, Category, Participant, SubCat, ParticipantFull} from '../services/dataClass';
import {ApiService} from '../services/api.service';
import {DatePipe} from '@angular/common';

export interface DialogData {
  name: string;
  description: string;
  startTime: string;
  stopTime: string;
  fbLink: string;
  instLink: string;
  soundLink: string;
  photo: string;
  fbLink2: string;
  instLink2: string;
  soundLink2: string;
  otherLink: string;
  subCat: string;
  showTimeBool: boolean;
}

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})

export class ProgramComponent implements OnInit, OnDestroy, AfterViewInit{

  private sub: any;
  isLoaded = 0;
  eventId = 0;
  event: Event[];
  eventDays: Date[];
  participants: ParticipantFull[] = [];
  participantsDisplayed: Participant[] = [];
  category: Category[];
  subCat: SubCat[];
  categoryDisplayed = 0;
  hoverIndex: number;

  constructor(public dialog: MatDialog, public api: ApiService, private datePipe: DatePipe, private route: ActivatedRoute) {}

  openParticipant(partId: number): void {
    const participant = this.getParticipant(partId);
    let partPhoto = '';
    let showTime = false;
    if (participant.photo2 === null) {
      if (participant.photo === null) {
        partPhoto = 'default.jpg';
      } else {
        partPhoto = '/assets/' + participant.photo;
      }
    }else {
      partPhoto = '/assets/photo_programme/' + participant.photo2;
    }
    const cat: number = +participant.categoryId;
    if (cat === 1) {
      showTime = true;
    }
    const dialogRef = this.dialog.open(ShowPartComponent, {
      width: '500px',
      maxHeight: '90vh',
      data: {name: participant.name, description: participant.description, photo : partPhoto,
        startTime: participant.hStart, stopTime: participant.hStop,
        fbLink: participant.fbLink, fbLink2: participant.fbLink2,
        instLink: participant.instLink, instLink2: participant.instLink2,
        soundLink: participant.scLink, soundLink2: participant.scLink2, otherLink: participant.otherLink,
        subCat: this.subCat[participant.subCatId].name, showTimeBool: showTime}
    });
  }

  getParticipant(id: number): ParticipantFull{
    let temp: ParticipantFull;
    let i = 0;
    while (temp === undefined && i < this.participants.length){
      if (id === this.participants[i].id){
        temp = this.participants[i];
      }
      i++;
    }
    return temp;
  }

  openTimeTable(cat: number, day: Date): void {
    const dialogRef = this.dialog.open(ShowTimetableComponent, {
      width: '800px',
      data: {cat, day}
    });
  }

  selectParticipant(id: number): void{
    this.categoryDisplayed = id;
    this.getEventDate();
    this.participantsDisplayed = [];
    for (const part of this.participants){
      const cat: number = +part.categoryId;
      const eve: number = +part.eventId;
      if (cat === this.categoryDisplayed && eve === this.eventId){
        part.dateShow = new Date(part.dateShow);
        this.participantsDisplayed.push(part);
      }
    }
    console.log('selected participants : ' + this.participantsDisplayed.length);
  }

  getEventDate(): void {
    this.eventDays = [];
    if (this.isLoaded >= 5) {
      let currentTempDay = new Date(this.event[this.eventId].dateStart);
      while (currentTempDay <= new Date(this.event[this.eventId].dateStop)) {
        this.eventDays.push(new Date(currentTempDay));
        currentTempDay = new Date(currentTempDay.getFullYear() + '-' + (currentTempDay.getMonth() + 1) + '-' +
          (currentTempDay.getDate() + 1));
      }

    }
  }

  enter(i): void {
    this.hoverIndex = i;
  }

  leave(i): void {
    this.hoverIndex = null;
  }

  loaded(): void {
    this.isLoaded = this.isLoaded + 1;
  }

  ngOnInit(): void {
    this.api.readEvent().subscribe((value: Event[]) => {
      this.event = value;
      this.loaded();
      console.log("event loaded");
    });
    this.api.readCategory().subscribe((data: Category[]) => {
      this.category = data;
      this.category.pop();
      this.category.pop();
      this.category.pop();
      this.loaded();
      console.log("cat loaded");
    });
    this.api.readSubCat().subscribe((data: SubCat[]) => {
      this.subCat = data;
      console.log(data);
      this.loaded();
      console.log("subcat loaded");
    });
    this.api.readParticipantsFull().subscribe((data: ParticipantFull[]) => {
      this.participants = data;
      this.participants.forEach(part => {
        if (part.photo === null){
          part.photo = 'negatif_tim_poisson1.png';
        } else {
          part.photo = 'photo_programme/' + part.photo;
        }
      });
      this.loaded();
      console.log("part loaded");
    });
    this.sub = this.route.params.subscribe(params => {
      this.eventId = +params.event;
      this.selectParticipant(this.categoryDisplayed);
      this.loaded();
      console.log("select loaded");
    });
  }

  // tslint:disable-next-line:typedef
  async ngAfterViewInit() {
    while (this.isLoaded < 4){
      await new Promise(f => setTimeout(f, 100));
      console.log(this.isLoaded);
    }
    this.selectParticipant(0);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
