<h1 mat-dialog-title class="text-uppercase text-center">Faire un virement banquaire pour soutenir la Foire</h1>
<hr>
<div mat-dialog-content class="text-uppercase text-center">
  <h2><strong>Merci de vouloir soutenir la foire !</strong></h2>
  <p>Pour nous effectuer un don, voici l'adresse IBAN de notre compte poste:</p>
  <h4 class="pt-2 font-weight-bold">IBAN :</h4>
  <p>CH88 0900 0000 1543 9103 7</p>
  <h4 class="pt-2 font-weight-bold">Destinataire : </h4>
  <p>La Sacrée Déter<br>Route de la Barre 5<br>1005 Lausanne</p>
  <p class="pt-3 font-italic">Merci de préciser en commentaire <strong>"DON pour la foire"</strong></p>
</div>
<div mat-dialog-actions class="row d-flex justify-content-end">
  <button mat-dialog-close class="btn btn-outline-dark">Fermer</button>
</div>
