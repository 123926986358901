<div class="container">
  <div class="text-uppercase text-center">
    <div class="display-4">
      Informations pratiques
    </div>
    <hr>
    <div class="h4">Quand ?</div>
    <p>La prochaine édition de la foire à lieu le : </p>
    <p><strong>21 et 22 août 2021</strong></p>
    <p> et se déroulera de : </p>
    <p><strong>13h à 01h</strong></p>
    <hr>
    <div class="h4">Où ?</div>
    <p>Le lieux qui acceuillera la foire cette année est <strong>la Cantine de Sauvabelin</strong> située à Lausanne</p>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-6 col-md-4 p-4">
        <div class="h5">Adresse :</div>
        <a href="https://www.google.com/maps/place/Cantine+De+Sauvabelin/@46.532731,6.6408625,15z/data=!4m5!3m4!1s0x0:0x1f8cc0e682d85500!8m2!3d46.5327954!4d6.6407449" target="_blank">
          <p>Route du Signal 46<br>1018 Lausanne</p>
        </a>
      </div>
      <div class="col-12 col-sm-6 col-md-4 p-4">
        <div class="h5">Coordonnées :</div>
        <a href="https://www.google.ch/maps/place/46%C2%B031'57.7%22N+6%C2%B038'26.9%22E" target="_blank">
          <p>46°31'57.7"N 6°38'26.9"E</p>
        </a>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-6 col-md-4 p-4">
        <div class="h5">Transport public :</div>
        <p> <u>Arrêt le plus proche :</u> <br>Lausanne, Signal</p>
        <p>Bus : <br><a href="https://www.t-l.ch/images/pdf/horaire/courant/16_a.pdf" target="_blank">16 direction Grand Vennes</a></p>
      </div>
      <div class="col-12 col-sm-6 col-md-4 p-4">
        <div class="h5">Voiture :</div>
        <p> <u>Parking le plus proche :</u> <br>
        <a href="https://www.google.ch/maps/place/Parking+de+Sauvabelin/@46.5327646,6.6392107,17z/data=!4m12!1m6!3m5!1s0x478c2e3ee5b49af9:0x1f8cc0e682d85500!2sCantine+De+Sauvabelin!8m2!3d46.5327954!4d6.6407449!3m4!1s0x478c2f627517b6fd:0x54297c4090a7e1b2!8m2!3d46.5328723!4d6.6401434">
          Parking de Sauvabelin
        </a><br><br>
        Payant de 8h à 18h<br>Max 3h <br>Gratuit le dimanche<br>80 places</p>
      </div>


    </div>
  </div>
</div>
