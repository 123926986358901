import {DatePipe} from '@angular/common';

export class Participant {
  id: number;
  name: string;
  description: string;
  categoryId: number;
  subCatId: number;
  fbLink: string;
  scLink: string;
  instLink: string;
  photo: string;
  dateShow: Date;
  eventId: number;
}

export class ParticipantFullRaw {
  id: number;
  name: string;
  eventId: number;
  dateShow: string;
  hStart: string;
  hStop: string;
  description: string;
  categoryId: number;
  subCatId: number;
  fbLink: string;
  scLink: string;
  instLink: string;
  fbLink2: string;
  scLink2: string;
  instLink2: string;
  otherLink: string;
  photo: string;
  photo2: string;

  constructor(input: ParticipantFull, private datePipe: DatePipe) {
    this.id = input.id;
    this.name = input.name;
    this.eventId = input.eventId;
    this.dateShow = this.datePipe.transform(input.dateShow, 'yyyy-MM-dd');
    this.hStart = this.datePipe.transform(input.hStart, 'HH:mm:ss');
    this.hStop = this.datePipe.transform(input.hStop, 'HH:mm:ss');
    this.description = input.description;
    this.categoryId = input.categoryId;
    this.subCatId = input.subCatId;
    this.fbLink = input.fbLink;
    this.scLink = input.scLink;
    this.instLink = input.instLink;
    this.fbLink2 = input.fbLink2;
    this.scLink2 = input.scLink2;
    this.instLink2 = input.instLink2;
    this.otherLink = input.otherLink;
    this.photo = input.photo;
    this.photo2 = input.photo2;
  }
}

export class ParticipantFull {
  id: number;
  name: string;
  eventId: number;
  dateShow: Date;
  hStart: Date;
  hStop: Date;
  description: string;
  categoryId: number;
  subCatId: number;
  fbLink: string;
  fbLink2: string;
  scLink: string;
  scLink2: string;
  instLink: string;
  instLink2: string;
  otherLink: string;
  photo: string;
  photo2: string;

  constructor(input: ParticipantFullRaw) {
    this.id = input.id;
    this.name = input.name;
    this.eventId = input.eventId;
    this.dateShow = new Date(input.dateShow);
    this.hStart = new Date(input.dateShow + ' ' + input.hStart);
    this.hStop = new Date(input.dateShow + ' ' + input.hStop);
    this.description = input.description;
    this.categoryId = input.categoryId;
    this.subCatId = input.subCatId;
    this.fbLink = input.fbLink;
    this.scLink = input.scLink;
    this.instLink = input.instLink;
    this.fbLink2 = input.fbLink2;
    this.scLink2 = input.scLink2;
    this.instLink2 = input.instLink2;
    this.otherLink = input.otherLink;
    this.photo = input.photo;
    this.photo2 = input.photo2;
  }
}

export class TimetableInput {
  day: Date;
  cat: number;

  constructor(day: Date, cat: number) {
    this.day = day;
    this.cat = cat;
  }
}

export class TimetableOutput {
  id: number;
  name: string;
  hStart: Date;
  hStop: Date;
  subCatId: number;

  constructor(input: TimetableOutputRaw) {
    this.id = input.id;
    this.name = input.name;
    this.hStart = new Date('1950-01-01 ' + input.hStart);
    this.hStop = new Date('1950-01-01 ' + input.hStop);
    this.subCatId = input.subCatId;
  }
}

export class TimetableOutputRaw {
  id: number;
  name: string;
  hStart: string;
  hStop: string;
  subCatId: number;
}

export class Category {
  id: number;
  name: string;
}

export class SubCat {
  id: number;
  catId: number;
  name: string;
}

export class Event {
  id: number;
  name: string;
  description: string;
  photo: string;
  dateStart: Date;
  dateStop: Date;
  hStart: Date;
  hStop: Date;
  fbLink: string;
}

export class MailInput {
  mail: string;
  name: string;
  message: string;

  constructor(mail: string, name: string, message: string) {
    this.mail = mail;
    this.name = name;
    this.message = message;
  }
}
