import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-soutenir',
  templateUrl: './soutenir.component.html',
  styleUrls: ['./soutenir.component.css']
})
export class SoutenirComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  openIbanDialog() {
    this.dialog.open(SoutenirComponentIbanDialog);
  }
  // tslint:disable-next-line:typedef
  openCashDialog() {
    this.dialog.open(SoutenirComponentCashDialog);
  }
  // tslint:disable-next-line:typedef
  openTwintDialog() {
    this.dialog.open(SoutenirComponentTwintDialog);
  }
  // tslint:disable-next-line:typedef
  openCryptoDialog() {
    this.dialog.open(SoutenirComponentCryptoDialog);
  }

}

@Component({
  selector: 'app-soutenir-dialog',
  templateUrl: 'soutenir-dialog-iban.html',
})
// tslint:disable-next-line:component-class-suffix
export class SoutenirComponentIbanDialog {}

@Component({
  selector: 'app-soutenir-dialog',
  templateUrl: 'soutenir-dialog-twint.html',
})
// tslint:disable-next-line:component-class-suffix
export class SoutenirComponentTwintDialog {}

@Component({
  selector: 'app-soutenir-dialog',
  templateUrl: 'soutenir-dialog-cash.html',
})
// tslint:disable-next-line:component-class-suffix
export class SoutenirComponentCashDialog {}

@Component({
  selector: 'app-soutenir-dialog',
  templateUrl: 'soutenir-dialog-crypto.html',
})
// tslint:disable-next-line:component-class-suffix
export class SoutenirComponentCryptoDialog {}
