<div class="h3">TIMETABLE of {{data.day | date:'EEEE d MMMM'}}</div>
<div mat-dialog-content>
  <div class="table-responsive">
    <table class="table ">
      <thead>
      <tr>
        <th style="width:30%"></th>
        <th style="width:40%"></th>
        <th style="width:20%"></th>
        <th style="width:10%"></th>
      </tr>
      </thead>
        <tr *ngFor="let part of dataSource">
          <td>
            <h4>{{part.hStart | date:'HH:mm'}} - {{part.hStop | date:'HH:mm'}}</h4>
          </td>
          <td>
            <h4>{{part.name}}</h4>
          </td>
          <td> <h5>{{subCat[part.subCatId].name}}</h5></td>
          <td> </td>
        </tr>
    </table>
  </div>
</div>

