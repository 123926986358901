import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { ApiService } from '../services/api.service';
import {Participant, TimetableInput, TimetableOutput, TimetableOutputRaw} from '../services/dataClass';

@Component({
  selector: 'app-home',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  isOpen = true;
  policies: Participant[];
  time: TimetableOutputRaw[];

  constructor(private apiService: ApiService) {}

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  ngOnInit(): void {
    console.log('init initiated...');
    this.apiService.readParticipants().subscribe((policies: Participant[]) => {
      this.policies = policies;
      console.log(policies[1].name);
    });
    this.apiService.readTimetable(new TimetableInput(new Date('2020-10-03'), 1)).subscribe((policies: TimetableOutputRaw[]) => {
      this.time = policies;
      console.log(policies[1].name);
    });
  }
}
