<h1 mat-dialog-title class="text-uppercase text-center">Faire un virement TWINT pour soutenir la Foire</h1>
<hr>
<div mat-dialog-content class="text-uppercase text-center">
  <h2><strong>Merci de vouloir soutenir la foire !</strong></h2>
  <p>Pour nous effectuer un don, voici le numéro de téléphone sur lequel vous pouvez envoyer votre don via TWINT:</p>
  <p>079 782 49 18</p>
  <p class="pt-3 font-italic">Merci de préciser en commentaire <strong>"DON pour la foire"</strong></p>
</div>
<div mat-dialog-actions class="row d-flex justify-content-end">
  <button mat-dialog-close class="btn btn-outline-dark">Fermer</button>
</div>
