<div class="container">
  <div class="container">
    <div class="text-uppercase text-center">
      <div class="display-4">
        Soutenir la Foire
      </div>
      <hr>
      <div class="h4">Staffer :</div>
      <p>Vous pouvez nous aider en staffant lors de la prochaine foire. </p>
      <a class="nav-link" routerLink="/participer"><h4 class="p-4"> > Staffer pour la foire ! < </h4></a>
      <hr>
      <div class="h4">Dons :</div>
      <p class="pb-2">Vous pouvez nous aider financièrement par plusieurs moyens : </p>
      <div class="d-flex flex-column">
        <div class="inline-flex p-3">
        <button class="btn btn-outline-dark" (click)="openIbanDialog()"> > Virement bancaire < </button>
        </div>
          <div class="inline-flex p-3">
        <button class="btn btn-outline-dark" (click)="openTwintDialog()"> > Twint < </button>
          </div>
        <div class="inline-flex p-3">
          <button class="btn btn-outline-dark" (click)="openCashDialog()"> > Cash < </button>
        </div>
      </div>
    </div>
  </div>
</div>
